import { navigate } from 'gatsby';
import React, { memo, useContext, useEffect } from 'react';
import LoadingScreen from './LoadingScreen';
import UserContext from '../../contexts/UserContext';
import {useAuthorizer} from '@authorizerdev/authorizer-react';

import {delay} from '../../utils';

const PrivateRoute = ({ component: Component, ...props }) => {
  const { user, loading, token } = useAuthorizer();

  
  if (loading) {
    return <LoadingScreen />;
  }
  
  if (!token && /\/[r]\/{1}[\w]{6}\/$/.test(window.location.href) === false) {
    navigate('/login');
    return null;
  }
  
  if ( typeof token !== 'undefined' && token !== null && 'access_token' in token && token.access_token !== null) {
    return (<Component user={user} {...props} />);
  } else {
    if(/\/[r]\/{1}[\w]{6}\/$/.test(window.location.href) === false) {
      return (<Component user={user} {...props} />);
    } else {
      console.log("no token");
      navigate('/login');
    }

  }

  //return (<Component user={user} {...props} />);
};

export default memo(PrivateRoute);
